/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { graphql } from "gatsby";
import { Footer, Grid, Layout } from "~components";
import { useAuth, useTheme, useWindowDimensions } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const ContactPage = ({ data, location }) => {
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  //

  const cms = data?.sanityContactPage;

  //

  return (
    <>
      <Layout>
        <div
          css={[
            css`
              background: ${lowImpactMode
                ? colours.background
                : colours.foreground};
              color: ${lowImpactMode ? colours.foreground : colours.background};

              padding-top: 56px;

              ${MEDIA_QUERIES?.desktop} {
                padding-top: 90px;
              }
            `,
            tw`relative`
          ]}
        >
          <Grid>
            <section
              css={[
                tw`col-span-12 md:col-span-6 md:h-screen relative md:sticky md:top-0 md:left-0 block md:pr-12 pt-12 md:pt-60 md:pb-24`
              ]}
            >
              <h1 css={[tw`text-h3 md:text-h2-md font-head`]}>
                {cms?.heading}
              </h1>
            </section>

            <section
              css={[
                tw`col-span-12 md:col-span-6 relative block pt-12 md:pt-60 md:pb-24`
              ]}
            >
              {cms?.content?.[0] && (
                <div css={[tw`w-full relative block pt-4`]}>
                  {cms.content.map((content) => {
                    let i;

                    return (
                      <article
                        key={`contact-section-${content.heading}`}
                        css={[tw`relative block mb-24`]}
                      >
                        <header>
                          <h2
                            css={[
                              tw`relative mb-5 pb-5 border-green-dark border-b text-b2 md:text-h4-md font-body`
                            ]}
                          >
                            {content.heading}
                          </h2>

                          {content?.subheading && (
                            <h3
                              css={[
                                tw`mb-3 text-b2 md:text-b1-md font-body italic`
                              ]}
                            >
                              {content.subheading}
                            </h3>
                          )}
                        </header>

                        <div
                          css={[tw`text-b1 md:text-b1-md font-body`]}
                          dangerouslySetInnerHTML={{ __html: content.body }}
                        />
                      </article>
                    );
                  })}
                </div>
              )}
            </section>
          </Grid>
        </div>

        <Footer />
      </Layout>
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage {
    sanityContactPage {
      heading
      content {
        heading
        subheading
        body
      }

      seoTitle
      seoDescription
      seoKeywords
    }
  }
`;
